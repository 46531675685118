// Init
import React, { lazy, Suspense, useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// Routes

import Navbar from '../components/Navbar.js';

const Home = lazy(() => import('../containers/Home.js'));
const Solution = lazy(() => import('../containers/Solution.js'));
const Service = lazy(() => import('../containers/Services.js'));
const About = lazy(() => import('../containers/About.js'));
const Contact = lazy(() => import('../containers/Contact.js'));
const ContactTwo = lazy(() => import('../containers/ContactTwo.js'));
const ContactThree = lazy(() => import('../containers/ContactThree.js'));
const ContactFour = lazy(() => import('../containers/ContactFour.js'));
const ServerDetails = lazy(() => import('../containers/ServiceDetails.js'));
const Bluetooth = lazy(() => import('../containers/bluetooth.js'));
const Agreement = lazy(() => import('../containers/Agreement.js'));
const Seo = lazy(() => import('../containers/Seo.js'));
const Manage = lazy(() => import('../containers/Managed.js'));
const Devop = lazy(() => import('../containers/Devops.js'));
const Ui = lazy(() => import('../containers/Uius.js'));
const Virtual = lazy(() => import('../containers/Virtual.js'));
const Transfer = lazy(() => import('../containers/Transformaion.js'));
const Ecommerce = lazy(() => import('../containers/Commerce.js'));
const Software = lazy(() => '../containers/Softwear.js');
const Offshore = lazy(() => '../containers/OffShore.js');
const OurProject = lazy(() => '../containers/Project.js');
const Faqpage = lazy(() => '../containers/Faq.js');
const Careers = lazy(() => '../containers/Careers.js');
const Joshua = lazy(() => '../containers/Careers.js');
const ThankYou = lazy(() => '../containers/ThankYou.js');

// import Payment from "../containers/Payment.js";
// import Payment from "../containers/Payment.js";
const CareerDetail = lazy(() => '../containers/Career/CareerDetail.js');
const Sales = lazy(() => '../containers/Career/Sales.js');

const Routing = ({ isDarkMode }) => {
  return (
    <Switch>
      <Suspense fallback={<h1>Loading...</h1>}>
        <Route
          exact
          path="/"
          component={() => <Home isDarkMode={isDarkMode} />}
        />
        <Route
          path="/solutions"
          component={() => <Solution isDarkMode={isDarkMode} />}
        />
        <Route
          path="/services"
          component={() => <Service isDarkMode={isDarkMode} />}
        />
        <Route
          path="/about-us"
          component={() => <About isDarkMode={isDarkMode} />}
        />

        <Route
          path="/contact-us"
          component={() => <Contact isDarkMode={isDarkMode} />}
        />
        <Route
          path="/payment"
          component={() => <ContactTwo isDarkMode={isDarkMode} />}
        />
        <Route
          path="/thankyou"
          component={() => <ContactThree isDarkMode={isDarkMode} />}
        />

        <Route
          path="/careers"
          component={() => <ContactFour isDarkMode={isDarkMode} />}
        />

        <Route path="/service/:servicename" component={ServerDetails} />
        <Route path="/bluetooth-app-development/" component={Bluetooth} />
        <Route path="/service-agreement" component={Agreement} />
        <Route path="/digital-marketing-and-seo-services/" component={Seo} />
        <Route path="/managed-it-service-provider/" component={Manage} />
        <Route path="/devops-services/" component={Devop} />
        <Route path="/ui-ux-design-services/" component={Ui} />
        <Route path="/virtual-cto-services/" component={Virtual} />
        <Route path="/digital-transformation-services/" component={Transfer} />
        <Route path="/ecommerce-development-services/" component={Ecommerce} />
        <Route path="/software-engineering-services/" component={Software} />
        <Route path="/offshore" component={Offshore} />
        <Route path="/project" component={OurProject} />
        <Route path="/faq" component={Faqpage} />

        <Route path="/careerDetail" component={CareerDetail} />
        <Route path="/sale" component={Sales} />
      </Suspense>
    </Switch>
  );
};
const Index = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  return (
    <BrowserRouter>
      <Navbar isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
      <Routing isDarkMode={isDarkMode} />
    </BrowserRouter>
  );
};

export default Index;
