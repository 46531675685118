import React, { useState, useEffect } from "react";
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import Logo from "../assets/logo/Genius-Fixer-Logo.svg";
import logolight from "../assets/logo/Genius-Fixer-Logo.svg";
import manu from "..//assets/logo/Menu.svg";
import manulight from "..//assets/logo/Nav_Bar_Trogle.svg";
import { Link } from "react-router-dom";

const NavbarComponent = ({ isDarkMode, setIsDarkMode }) => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  useEffect(() => {
    const handleScroll = () => {
      const isMobile = window.innerWidth <= 767; // Adjust the breakpoint as needed
      const isLastSection =
        isMobile &&
        window.innerHeight + window.scrollY >= document.body.offsetHeight;

      if (navbarOpen && !isLastSection) {
        setNavbarOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [navbarOpen]);

  const toggleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  return (
    <Navbar
      expand="lg"
      style={{ zIndex: "10" }}
      className={!isDarkMode ? "bg__dark pb-3 pt-3" : "bg__light pb-3 pt-3"}
      bg=""
      variant="dark"
      fixed="top"
      expanded={navbarOpen}
    >
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img
              src={!isDarkMode ? Logo : logolight}
              className="d-inline-block align-top logo-header"
              alt="Genius Fixers Logo"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleNavbar}>
          <img
            src={!isDarkMode ? manu : manulight}
            className="d-inline-block align-top"
            alt="Hamburger"
          />
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto  nav-li">
            <Link
              onClick={scrollTop}
              id="lihome"
              className={!isDarkMode ? "bg__dark-color " : "bg__light-color"}
              to="/"
            >
              Home{" "}
            </Link>
            <Link
              id="liSolution"
              onClick={scrollTop}
              className={!isDarkMode ? "bg__dark-color " : "bg__light-color"}
              to="/solutions"
            >
              Solution
            </Link>
            <Link
              id="liservices"
              onClick={scrollTop}
              className={!isDarkMode ? "bg__dark-color " : "bg__light-color"}
              to="/services"
            >
              Services
            </Link>

            <Link
              id="liabout"
              onClick={scrollTop}
              className={!isDarkMode ? "bg__dark-color " : "bg__light-color"}
              to="/about-us"
            >
              About us
            </Link>
            <Link
              id="liabout"
              onClick={scrollTop}
              className={!isDarkMode ? "bg__dark-color " : "bg__light-color"}
              to="/careers"
            >
             Careers
            </Link>
            <Link
              id="licontact"
              onClick={scrollTop}
              className={!isDarkMode ? "bg__dark-color " : "bg__light-color"}
              to="/contact-us"
            >
              Contact us
            </Link>
            <a
              id="liproject"
              className={
                !isDarkMode ? "bg__dark-color pl-1 " : "bg__light-color"
              }
              href="https://geniusfixers.screenconnect.com"
              // href="www.startcontrol.com"
              target="_Blank"
            >
              Remote Support
            </a>
          </Nav>
          <div className=" payment-btnnn">
            <Button className="common-btn-color" style={{ marginLeft: "20px" }}>
              +1 (703) 419-9000{" "}
            </Button>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
